import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Modal, Tooltip, message } from "antd";
import { dateFormat, formatTime } from "../../utils/Utils";
import DateTimeFormat from "../../components/DateTimeFormat/DateTimeFormat";
import DateFormat from "../../components/DateTimeFormat/DateFormat";
import { useMediaQuery } from "react-responsive";
import * as XLSX from 'xlsx';
import { 
  FaFileExcel, 
  FaPills, 
  FaInfoCircle, 
  FaLightbulb,
  FaCalendarAlt,
  FaUserMd
} from "react-icons/fa";

const Medications = () => {
  const [userData, setUserData] = useState(null);
  const [viewData, setViewData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [lastExportedFile, setLastExportedFile] = useState(null);
  
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  useEffect(() => {
    const userDataString = sessionStorage.getItem('userData');
    if (userDataString) {
      const userDataObject = JSON.parse(userDataString);
      if (userDataObject.prescriptions) {
        userDataObject.prescriptions.sort((a, b) => new Date(b.date_time) - new Date(a.date_time));
      }
      setUserData(userDataObject);
    }
  }, []);
  
  const getColumns = () => {
    return [
      {
        title: '#',
        key: '#',
        render: (_, __, index) => index + 1,
        width: isMobile ? 40 : 50,
      },
      {
        title: 'ID',
        key: 'id',
        dataIndex: 'prescription_code',
        width: isMobile ? 120 : (isTablet ? 150 : 180),
        ellipsis: true,
      },
      {
        title: "Medication",
        dataIndex: "prescriptions",
        key: "prescriptions",
        width: isMobile ? 150 : (isTablet ? 180 : 'auto'),
        ellipsis: true,
        render: (prescriptions) => {
          if (!prescriptions || prescriptions.length === 0) return "N/A";
          
          const allMeds = prescriptions.map(p => p.searched_medicine).join(", ");
          
          return (
            <Tooltip title={allMeds}>
              <span>
                {prescriptions.length <= 3 ? (
                  prescriptions.map((prescription, index) => (
                    <span key={prescription.key || index}>
                      {index > 0 ? ", " : ""}
                      {prescription?.searched_medicine?.length > 30
                        ? prescription?.searched_medicine?.slice(0, 30) + "..."
                        : prescription?.searched_medicine}
                    </span>
                  ))
                ) : (
                  <>
                    <span>
                      {prescriptions[0]?.searched_medicine?.length > 30
                        ? prescriptions[0]?.searched_medicine?.slice(0, 30) + "..."
                        : prescriptions[0]?.searched_medicine}
                      {", "} +{prescriptions.length - 1} more
                    </span>
                  </>
                )}
              </span>
            </Tooltip>
          );
        },
      },
      {
        title: "Provider",
        dataIndex: "added_by",
        key: "added_by",
        align: "center",
        width: isMobile ? 120 : (isTablet ? 150 : 180),
        ellipsis: true,
        render: (text) => (
          <Tooltip title={text}>
            <span>{text}</span>
          </Tooltip>
        ),
      },
      {
        title: "Date",
        dataIndex: "date_time",
        key: "date_time",
        align: 'center',
        width: isMobile ? 100 : 120,
        sorter: (a, b) => {
          const dateA = new Date(a.date_time);
          const dateB = new Date(b.date_time);
          return dateA - dateB;
        },
        render: (text) => (
          <Tooltip title={<DateTimeFormat date={text} type="time" />}>
            <span>{text ? <DateTimeFormat date={text} type="date" /> : ""}</span>
          </Tooltip>
        ),
      },
      {
        dataIndex: "action",
        key: "action",
        title: "Action",
        align: 'center',
        width: isMobile ? 100 : 180,
        fixed: isMobile ? 'right' : undefined,
        render: (text, record) => (
          <Button type="primary" size="small" onClick={() => handleView(record)}>
            {isMobile ? "View" : "View Prescriptions"}
          </Button>
        ),
      },
    ];
  };

  const handleView = (record) => {
    setViewData(record);
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
  };

  // Function to export data to Excel
  const exportToExcel = () => {
    try {
      if (!userData?.prescriptions || userData.prescriptions.length === 0) {
        message.warning('No prescription data available to export');
        return;
      }

      setExporting(true);
      
      setTimeout(() => {
        try {
          // Create a clean dataset for export
          const exportData = userData.prescriptions.map(record => {
            const medications = record.prescriptions?.map(p => p.searched_medicine).join(", ") || "N/A";
            
            return {
              "Prescription ID": record.prescription_code,
              "Medications": medications,
              "Provider": record.added_by,
              "Date": new Date(record.date_time).toLocaleString(),
            };
          });

          // Create a worksheet
          const worksheet = XLSX.utils.json_to_sheet(exportData);
          
          // Set column widths
          const columnWidths = [
            { wch: 20 }, // Prescription ID
            { wch: 40 }, // Medications
            { wch: 25 }, // Provider
            { wch: 20 }, // Date
          ];
          worksheet['!cols'] = columnWidths;
          
          // Create a workbook
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Prescriptions");
          
          // Generate filename with date
          const date = new Date();
          const dateStr = date.toISOString().split('T')[0]; // YYYY-MM-DD format
          const fileName = `Prescriptions_${dateStr}.xlsx`;
          
          // Export the file
          XLSX.writeFile(workbook, fileName);
          
          setLastExportedFile(fileName);
          message.success(`Prescription data exported successfully as ${fileName}`);
        } catch (error) {
          console.error('Export error:', error);
          message.error('Failed to export data. Please try again.');
        } finally {
          setExporting(false);
        }
      }, 800);
    } catch (error) {
      console.error('Export error:', error);
      message.error('Failed to export data. Please try again.');
      setExporting(false);
    }
  };

  return (
    <div className="medications-container">
      <div className="flex justify-between items-center mb-4">
        <Typography.Title level={4} className="mb-0">
          Prescriptions
        </Typography.Title>
        <Button 
          type="default" 
          icon={<FaFileExcel className="text-green-600" />} 
          onClick={exportToExcel}
          disabled={!userData?.prescriptions || userData.prescriptions.length === 0 || exporting}
          loading={exporting}
          className="export-excel-button"
        >
          {exporting ? 'Exporting...' : 'Export to Excel'}
        </Button>
      </div>
      
      <div className="mb-4 p-3 bg-gray-50 rounded-lg border border-gray-100">
        <div className="flex items-center text-gray-600">
          <FaInfoCircle className="mr-2 text-blue-500" />
          <span>
            {userData?.prescriptions?.length || 0} prescription{(userData?.prescriptions?.length !== 1) ? 's' : ''} found. 
            Click on column headers to sort. Use the "View Prescriptions" button to see details.
          </span>
        </div>
        {lastExportedFile && (
          <div className="mt-2 text-xs text-gray-500 flex items-center">
            <FaFileExcel className="mr-1 text-green-600" /> 
            Last exported: <span className="font-medium ml-1">{lastExportedFile}</span>
          </div>
        )}
      </div>
      
      <div className="table-responsive">
        <Table
          size={isMobile ? "small" : "middle"}
          dataSource={userData?.prescriptions || []}
          columns={getColumns()}
          pagination={{
            pageSize: 10,
            responsive: true,
            showSizeChanger: !isMobile,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
          scroll={{ x: isMobile ? 600 : 800 }}
          loading={!userData}
          className="responsive-table vitals-data-table"
          rowKey={(record) => record?.prescription_code || Math.random().toString(36).substring(2, 9)}
          rowClassName={(record, index) => index % 2 === 0 ? 'bg-gray-50' : ''}
        />
      </div>

      <Modal
        title={
          <div className="flex items-center">
            <div className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-100 mr-3">
              <FaPills className="text-blue-500 text-xl" />
            </div>
            <div>
              <span className="text-xl font-semibold">Prescription Details</span>
              <p className="text-sm text-gray-500 mt-1">
                Prescription ID: {viewData?.prescription_code}
              </p>
            </div>
          </div>
        }
        open={visible}
        footer={[
          <Button key="close" type="primary" onClick={onCancel}>
            Close
          </Button>
        ]}
        onCancel={onCancel}
        maskClosable={false}
        width={isMobile ? "95%" : 650}
        centered
        className="vitals-data-modal"
      >
        <div className="mb-4 p-3 bg-blue-50 rounded-lg border border-blue-100">
          <div className="flex items-start">
            <FaLightbulb className="mr-2 text-yellow-500 mt-1 flex-shrink-0" />
            <span className="text-gray-600">
              <strong>About your prescriptions:</strong> Always take medications as prescribed. 
              Contact your provider if you experience any side effects or have questions.
            </span>
          </div>
        </div>
        
        <div className="prescription-details mb-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex items-center">
              <div className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-100 mr-2">
                <FaUserMd className="text-blue-500" />
              </div>
              <div>
                <div className="text-sm text-gray-500">Provider</div>
                <div className="font-medium">{viewData?.added_by}</div>
              </div>
            </div>
            
            <div className="flex items-center">
              <div className="flex items-center justify-center w-8 h-8 rounded-full bg-green-100 mr-2">
                <FaCalendarAlt className="text-green-500" />
              </div>
              <div>
                <div className="text-sm text-gray-500">Date Added</div>
                <div className="font-medium">
                  <DateTimeFormat date={viewData?.date_time} type="datetime"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="medications-list">
          <h3 className="text-lg font-semibold mb-3">Prescribed Medications</h3>
          {viewData?.prescriptions?.map((item, index) => (
            <div className="mb-4 bg-gray-50 p-4 rounded-lg border border-gray-100 hover:shadow-md transition-shadow duration-300" key={index}>
              <h4 className="text-md font-semibold text-blue-600 mb-2">{item?.searched_medicine}</h4>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div>
                  <p className="mb-1">
                    <span className="font-medium text-gray-700">Instructions: </span>
                    <span>{item?.instructions || "No instructions provided"}</span>
                  </p>
                  <p className="mb-1">
                    <span className="font-medium text-gray-700">Quantity: </span>
                    <span>{item?.dispense_quantity || "Not specified"}</span>
                  </p>
                  <p className="mb-1">
                    <span className="font-medium text-gray-700">Refills: </span>
                    <span>{item?.refills || "0"}</span>
                  </p>
                </div>
                
                <div>
                  <p className="mb-1">
                    <span className="font-medium text-gray-700">Dispense as Written: </span>
                    <span>
                      {item?.dispense_as_written?.toString() === "true"
                        ? "Yes"
                        : "No"}
                    </span>
                  </p>
                  <p className="mb-1">
                    <span className="font-medium text-gray-700">Start Date: </span>
                    {item?.start_date ? <DateFormat date={item?.start_date}/> :"No Date Entered"}
                  </p>
                  <p className="mb-1">
                    <span className="font-medium text-gray-700">End Date: </span>
                    {item?.end_date ? <DateFormat date={item?.end_date}/> :"No Date Entered"}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default Medications;
