import { Button, Card, Modal, Table, Empty, Spin, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import DateTimeFormat from "../../components/DateTimeFormat/DateTimeFormat";
import * as XLSX from 'xlsx';
import { 
  FaHeartbeat, 
  FaWeight, 
  FaRulerVertical, 
  FaThermometerHalf, 
  FaLungs, 
  FaPercentage,
  FaTachometerAlt,
  FaUserMd,
  FaVenusMars,
  FaBirthdayCake,
  FaBalanceScale,
  FaFileDownload,
  FaInfoCircle,
  FaLightbulb,
  FaFileExcel
} from "react-icons/fa";
import { MdBloodtype } from "react-icons/md";

const vitalsColumn = [
  {
    title: "Date",
    dataIndex: "date_time",
    key: "date_time",
    render: (text) => (
      <DateTimeFormat date={text} type="datetime"/>
    ),
  },
  {
    title: "Blood Pressure",
    dataIndex: "blood_pressure",
    key: "blood_pressure",
    align: "center",
  },
  {
    title:"Height",
     align: 'center',
    dataIndex:"height",
    key:"height"
  },
  {
    title:"Weight",
     align: 'center',
    dataIndex:'weight' ,
    key:'weight'
  },
  {
    title: "Heart Rate",
    dataIndex: "heart_rate",
    key: "heart_rate",
    align: "center",
  },
  {
    title: "O2 Saturation",
    dataIndex: "oxygen",
    key: "oxygen",
    align: "center",
    render: (oxygen)=> `${oxygen ?`${oxygen}%`: ""}`
  },
  {
    title: "Body Temperature",
    dataIndex: "body_temp",
    key: "body_temp",
    align: "center",
    render: (temperature) => {
      return temperature >= 100 && temperature <= 110 ? (
        <p className="text-red-500">{temperature} F</p>
      ) : temperature >= 90 && temperature <= 100 ? (
        <p className="text-green-500">{temperature} F</p>
      ) : temperature >= 30 && temperature < 40 ? (
        <p className="text-green-500">{temperature} C </p>
      ) : temperature >= 40 && temperature <= 45 ? (
        <p className="text-red-500">{temperature} C </p>
      ) : (
        <p>{temperature}</p>
      );
    },
  },
  {
    title: "Respiratory Rate",
    dataIndex: "resp_rate",
    key: "resp_rate",
    align: "center",
  },
  {
    title: "Pain Scale",
    dataIndex: "pain_scale",
    key: "pain_scale",
    align: "center",
  },
  {
    title: "Added By",
    dataIndex: "added_by",
    key: "added_by",
  },
];

const bmiColumn = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
      render : (text) => 
      <DateTimeFormat date={text} type="datetime"/>
    },
    {
      title: "Height",
      dataIndex: "BMI_height",
      key: "BMI_height",
      align: 'center'
    },
    {
      title: "Weight",
      dataIndex: "BMI_weight",
      key: "BMI_weight",
      align: 'center'
    },
    {
      title: "BMI",
      dataIndex: "BMI",
      key: "BMI",
      align: 'center',
      render : (text) => {
        return(
          text < 18.5 
          ? <p className="text-red-300">{text }</p>
          :text >= 18.5 && text <=24.9
          ?  <p className="text-green-500">{text }</p>
          : text >= 25 && text <=29.9 
          ? <p className="text-yellow-500">{text }</p>
          : text >= 30 
          ?  <p className="text-red-300">{text }</p>
          : <p>{text }</p>
        )
      }
    },
    {
      title: "BMI Category",
      dataIndex: "BMI",
      key: "BMI",
      align: 'center',
      render : (text) => {
        return(
          text < 18.5 
          ? <p className="text-red-300"> Underweight </p>
          :text >= 18.5 && text <=24.9
          ?  <p className="text-green-500">Normal Weight </p>
          : text >= 25 && text <=29.9 
          ? <p className="text-yellow-500">Overweight</p>
          : text >= 30 
          ?  <p className="text-red-300">Obesity</p>
          : <p>{text }</p>
        )
      }
    },
    {
      title: 'Added By',
      dataIndex: 'added_by',
      key: 'added_by'
    }
  ]
  
  const idealWeightColumn = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
      render : (text) => 
      <DateTimeFormat date={text} type="datetime"/>
    },
  
    {
      title: "Height",
      dataIndex: "idealweight_height",
      key: "idealweight_height",
      align:'center'
    },
    {
    title: 'Gender',
    dataIndex: 'idealweight_gender',
    key:'idealweight_gender',
    align:'center'
    },
    {
      title: "Ideal Weight",
      dataIndex: "idealweight",
      key: "idealweight",
      align:'center'
    },
    {
      title: 'Added By',
      dataIndex: 'added_by',
      key: 'added_by'
    }
  ]
  
  const bodyFatColumn = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
      render : (text) => 
     <DateTimeFormat date={text} type="datetime"/>
    },
    {
      title: "Age",
      dataIndex: "bodyfat_waist",
      key: "bodyfat_waist",
    },
    {
      title: 'Gender',
      dataIndex: 'bodyfat_neck',
      key: 'bodyfat_neck'
    },
    {
      title: "Height",
      dataIndex: "bodyfat_height",
      key: "bodyfat_height",
      align: 'center'
    },
    {
      title: "Weight",
      dataIndex: "bodyfat_weight",
      key: "bodyfat_weight",
      align: 'center'
    },
    {
      title: 'Body Fat Percentage',
      dataIndex: 'bodyfat',
      key: 'bodyfat',
      align: 'center'
    },
    {
      title: 'Added By',
      dataIndex: 'added_by',
      key: 'added_by'
    }
  ]

const RecordedVitals = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalData, setModalData] = useState([]);
  const [modalColumns, setModalColumns] = useState([]);
  const [exporting, setExporting] = useState(false);
  const [lastExportedFile, setLastExportedFile] = useState(null);

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userDataObject = JSON.parse(userDataString);
      setUserData(userDataObject);
      setLoading(false);
    }
  }, []);

  const hasBasicVitals = userData?.vitals && userData.vitals.length > 0;
  const hasBMI = userData?.bmi && userData.bmi.length > 0;
  const hasBodyFat = userData?.body_fat && userData.body_fat.length > 0;
  const hasIdealWeight = userData?.ideal_weight && userData.ideal_weight.length > 0;

  const showModal = (title, data, columns) => {
    setModalTitle(title);
    setModalData(data);
    setModalColumns(columns);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  // Function to export data to Excel
  const exportToExcel = () => {
    try {
      if (!modalData || modalData.length === 0) {
        message.warning('No data available to export');
        return;
      }

      setExporting(true);
      
      // Simulate a slight delay to show loading state (can be removed in production)
      setTimeout(() => {
        try {
          // Create a clean dataset for export by processing the data
          const exportData = modalData.map(record => {
            const cleanRecord = {};
            
            // Process each column to get clean data
            modalColumns.forEach(column => {
              if (column.dataIndex && column.title) {
                // Get the raw value
                let value = record[column.dataIndex];
                
                // Handle special formatting for dates
                if (column.dataIndex === 'date_time' && value) {
                  const date = new Date(value);
                  if (!isNaN(date)) {
                    value = date.toLocaleString();
                  }
                }
                
                // Use the column title as the key in the exported data
                cleanRecord[column.title] = value;
              }
            });
            
            return cleanRecord;
          });

          // Create a worksheet
          const worksheet = XLSX.utils.json_to_sheet(exportData);
          
          // Create column widths for better readability
          const columnWidths = [];
          modalColumns.forEach(() => {
            columnWidths.push({ wch: 20 }); // Set width for each column
          });
          worksheet['!cols'] = columnWidths;
          
          // Create a workbook
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, modalTitle);
          
          // Generate filename with date
          const date = new Date();
          const dateStr = date.toISOString().split('T')[0]; // YYYY-MM-DD format
          const fileName = `${modalTitle.replace(/\s+/g, '_')}_${dateStr}.xlsx`;
          
          // Export the file
          XLSX.writeFile(workbook, fileName);
          
          setLastExportedFile(fileName);
          message.success(`${modalTitle} data exported successfully as ${fileName}`);
        } catch (error) {
          console.error('Export error:', error);
          message.error('Failed to export data. Please try again.');
        } finally {
          setExporting(false);
        }
      }, 800); // Slight delay for UX
    } catch (error) {
      console.error('Export error:', error);
      message.error('Failed to export data. Please try again.');
      setExporting(false);
    }
  };

  // Get category-specific styling and information
  const getCategoryInfo = (title) => {
    switch(title) {
      case "Basic Vitals":
        return {
          icon: <FaHeartbeat className="text-2xl" />,
          color: "#1890ff",
          description: "Your basic vital signs are key indicators of your overall health status.",
          emptyMessage: "No basic vitals have been recorded yet."
        };
      case "BMI":
        return {
          icon: <FaWeight className="text-2xl" />,
          color: "#52c41a",
          description: "Body Mass Index (BMI) is a measure of body fat based on height and weight.",
          emptyMessage: "No BMI measurements have been recorded yet."
        };
      case "Body Fat":
        return {
          icon: <FaPercentage className="text-2xl" />,
          color: "#722ed1",
          description: "Body fat percentage is the total mass of fat divided by total body mass.",
          emptyMessage: "No body fat measurements have been recorded yet."
        };
      case "Ideal Weight":
        return {
          icon: <FaBalanceScale className="text-2xl" />,
          color: "#13c2c2",
          description: "Ideal weight is an estimated weight range for optimal health based on height and gender.",
          emptyMessage: "No ideal weight calculations have been recorded yet."
        };
      default:
        return {
          icon: <FaHeartbeat className="text-2xl" />,
          color: "#1890ff",
          description: "Your health records provide important information about your wellbeing.",
          emptyMessage: "No data has been recorded yet."
        };
    }
  };

  const categoryButtons = [
    {
      key: "basicVitals",
      title: "Basic Vitals",
      icon: <FaHeartbeat />,
      color: "#1890ff",
      available: hasBasicVitals,
      onClick: () => showModal("Basic Vitals", userData?.vitals || [], vitalsColumn),
      count: userData?.vitals?.length || 0
    },
    {
      key: "bmi",
      title: "BMI",
      icon: <FaWeight />,
      color: "#52c41a",
      available: hasBMI,
      onClick: () => showModal("BMI", userData?.bmi || [], bmiColumn),
      count: userData?.bmi?.length || 0
    },
    {
      key: "bodyFat",
      title: "Body Fat",
      icon: <FaPercentage />,
      color: "#722ed1",
      available: hasBodyFat,
      onClick: () => showModal("Body Fat", userData?.body_fat || [], bodyFatColumn),
      count: userData?.body_fat?.length || 0
    },
    {
      key: "idealWeight",
      title: "Ideal Weight",
      icon: <FaBalanceScale />,
      color: "#13c2c2",
      available: hasIdealWeight,
      onClick: () => showModal("Ideal Weight", userData?.ideal_weight || [], idealWeightColumn),
      count: userData?.ideal_weight?.length || 0
    }
  ];

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Spin size="large" tip="Loading vitals data..." />
      </div>
    );
  }

  return (
    <div className="vitals-container">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        {categoryButtons.map((button) => (
          <Button
            key={button.key}
            type="default"
            size="large"
            className="vitals-category-button"
            onClick={button.onClick}
            disabled={!button.available}
            style={{
              borderColor: button.color,
              backgroundColor: button.available ? `${button.color}10` : '#f5f5f5',
              height: 'auto',
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '12px',
              transition: 'all 0.3s ease'
            }}
          >
            <div 
              style={{ 
                color: button.color, 
                fontSize: '24px', 
                marginBottom: '8px' 
              }}
            >
              {button.icon}
            </div>
            <div style={{ fontWeight: 600, marginBottom: '4px', color: button.available ? '#333' : '#999' }}>
              {button.title}
            </div>
            {button.available && (
              <div style={{ fontSize: '12px', color: '#666' }}>
                {button.count} record{button.count !== 1 ? 's' : ''}
              </div>
            )}
            {!button.available && (
              <div style={{ fontSize: '12px', color: '#999' }}>
                No data available
              </div>
            )}
          </Button>
        ))}
      </div>

      {!hasBasicVitals && !hasBMI && !hasBodyFat && !hasIdealWeight && (
        <Empty 
          description="No vitals data recorded" 
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          className="my-8"
        />
      )}

      <Modal
        title={
          <div className="flex items-center">
            <div 
              className="flex items-center justify-center w-10 h-10 rounded-full mr-3"
              style={{ backgroundColor: `${getCategoryInfo(modalTitle).color}15` }}
            >
              <span style={{ color: getCategoryInfo(modalTitle).color }}>
                {getCategoryInfo(modalTitle).icon}
              </span>
            </div>
            <div>
              <span className="text-xl font-semibold">{modalTitle}</span>
              <p className="text-sm text-gray-500 mt-1">{getCategoryInfo(modalTitle).description}</p>
            </div>
          </div>
        }
        open={modalVisible}
        onCancel={handleCloseModal}
        footer={[
          <Button 
            key="export" 
            type="default" 
            icon={<FaFileExcel className="text-green-600" />} 
            onClick={exportToExcel}
            disabled={!modalData || modalData.length === 0 || exporting}
            loading={exporting}
            className="export-excel-button"
          >
            {exporting ? 'Exporting...' : 'Export to Excel'}
          </Button>,
          <Button key="close" type="primary" onClick={handleCloseModal}>
            Close
          </Button>
        ]}
        width={900}
        className="vitals-data-modal"
      >
        {modalData.length === 0 ? (
          <Empty 
            description={getCategoryInfo(modalTitle).emptyMessage}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="my-8"
          />
        ) : (
          <>
            <div className="mb-4 p-3 bg-gray-50 rounded-lg border border-gray-100">
              <div className="flex items-center text-gray-600">
                <FaInfoCircle className="mr-2 text-blue-500" />
                <span>
                  {modalData.length} record{modalData.length !== 1 ? 's' : ''} found. 
                  <span className="ml-1 text-green-600 font-medium">
                    You can export this data to Excel using the button below.
                  </span>
                </span>
              </div>
              {lastExportedFile && (
                <div className="mt-2 text-xs text-gray-500 flex items-center">
                  <FaFileExcel className="mr-1 text-green-600" /> 
                  Last exported: <span className="font-medium ml-1">{lastExportedFile}</span>
                </div>
              )}
            </div>
            
            <Table
              dataSource={modalData}
              columns={modalColumns}
              rowKey={(record) => record.id || Math.random().toString(36).substring(2, 9)}
              pagination={{ 
                pageSize: 5,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` 
              }}
              scroll={{ x: 'max-content' }}
              className="vitals-data-table"
              rowClassName={(record, index) => index % 2 === 0 ? 'bg-gray-50' : ''}
              onRow={(record) => ({
                onClick: () => {
                  // Could show a detailed view of the record here
                  console.log('Record clicked:', record);
                },
              })}
            />
            
            <div className="mt-4 p-3 bg-blue-50 rounded-lg border border-blue-100">
              <div className="flex items-start text-gray-600">
                <FaLightbulb className="mr-2 text-yellow-500 mt-1 flex-shrink-0" />
                <span>
                  <strong>Understanding your {modalTitle.toLowerCase()}:</strong> Regular monitoring helps track your health progress over time. 
                  Discuss any significant changes with your healthcare provider.
                </span>
              </div>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default RecordedVitals;
