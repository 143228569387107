import React, { useEffect, useState } from "react";
import { Navigate , Route } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";

// import { useProfile } from "../Hooks/UserHooks";

const AuthProtected = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Check if user data exists in session storage
    const userDataString = sessionStorage.getItem("userData");
    
    if (userDataString) {
      try {
        const userData = JSON.parse(userDataString);
        
        // Check if the user has the required clinic information
        const hasRequiredInfo = userData && 
          userData.patient_info;
        
        setIsAuthenticated(hasRequiredInfo);
      } catch (error) {
        console.error("Error parsing user data:", error);
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
    
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
