import { Button, Modal, Typography, Image, Space } from "antd";
import React from "react";
import {
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import DateTimeFormat from "../../../components/DateTimeFormat/DateTimeFormat";
import ViewTemplate from "../../../components/ViewTemplate";
import { 
  FaVial, 
  FaFileAlt, 
  FaLightbulb, 
  FaCalendarAlt, 
  FaUserMd, 
  FaClipboardList 
} from "react-icons/fa";

const ViewLabResults = ({ visible, onClose, data, fileData, loading, className }) => {
  const showFile = (fileContent) => {
    window.open(fileContent, "_blank");
  };

  const renderFileContent = () => {
    const fileExtension = fileData?.type?.split("/").pop();
    if (fileExtension === "pdf") {
      return (
        <div className="pdf-container flex flex-col items-center justify-center p-8 bg-gray-50 rounded-lg border border-gray-200 mb-4">
          <FilePdfOutlined style={{ fontSize: "64px", color: "#ff0000" }} />
          <Typography.Text strong className="pdf-label mt-2 text-lg">
            PDF Document
          </Typography.Text>
          <Typography.Text className="text-gray-500 text-sm mt-1">
            Click "View File" below to open the PDF in a new tab
          </Typography.Text>
        </div>
      );
    } else {
      return (
        <div className="image-container p-2 bg-gray-50 rounded-lg border border-gray-200 mb-4">
          <Image
            className="image-preview"
            src={fileData?.url}
            preview={{
              toolbarRender: (
                _,
                {
                  transform: { scale },
                  actions: {
                    onFlipY,
                    onFlipX,
                    onRotateLeft,
                    onRotateRight,
                    onZoomOut,
                    onZoomIn,
                  },
                }
              ) => (
                <Space size={12} className="toolbar-wrapper">
                  <SwapOutlined rotate={90} onClick={onFlipY} />
                  <SwapOutlined onClick={onFlipX} />
                  <RotateLeftOutlined onClick={onRotateLeft} />
                  <RotateRightOutlined onClick={onRotateRight} />
                  <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                  <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                </Space>
              ),
            }}
          />
        </div>
      );
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={[
        <Button 
          key="view" 
          type="primary" 
          onClick={() => showFile(fileData && fileData?.url)}
          icon={<FaFileAlt />}
        >
          View File
        </Button>,
        <Button key="close" type="primary" onClick={onClose}>
          Close
        </Button>
      ]}
      width={900}
      title={
        <div className="flex items-center">
          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-purple-100 mr-3">
            <FaVial className="text-purple-500 text-xl" />
          </div>
          <div>
            <span className="text-xl font-semibold">Lab Result</span>
            <p className="text-sm text-gray-500 mt-1">
              {data?.filename}
            </p>
          </div>
        </div>
      }
      confirmLoading={loading}
      className={`lab-results-modal ${className || ''}`}
    >
      <div className="mb-4 p-3 bg-blue-50 rounded-lg border border-blue-100">
        <div className="flex items-start">
          <FaLightbulb className="mr-2 text-yellow-500 mt-1 flex-shrink-0" />
          <span className="text-gray-600">
            <strong>About your lab results:</strong> These documents contain important information about your health. 
            If you have questions about your results, please contact your healthcare provider.
          </span>
        </div>
      </div>
      
      <div className="lab-details mb-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex items-center">
            <div className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-100 mr-2">
              <FaUserMd className="text-blue-500" />
            </div>
            <div>
              <div className="text-sm text-gray-500">Uploaded By</div>
              <div className="font-medium">{data?.added_by}</div>
            </div>
          </div>
          
          <div className="flex items-center">
            <div className="flex items-center justify-center w-8 h-8 rounded-full bg-green-100 mr-2">
              <FaCalendarAlt className="text-green-500" />
            </div>
            <div>
              <div className="text-sm text-gray-500">Date Added</div>
              <div className="font-medium">
                <DateTimeFormat date={data?.date_time} type="datetime"/>
              </div>
            </div>
          </div>
          
          <div className="flex items-center">
            <div className="flex items-center justify-center w-8 h-8 rounded-full bg-purple-100 mr-2">
              <FaClipboardList className="text-purple-500" />
            </div>
            <div>
              <div className="text-sm text-gray-500">Lab Order ID</div>
              <div className="font-medium">{data?.lab_order_id}</div>
            </div>
          </div>
          
          <div className="flex items-center">
            <div className="flex items-center justify-center w-8 h-8 rounded-full bg-indigo-100 mr-2">
              <FaVial className="text-indigo-500" />
            </div>
            <div>
              <div className="text-sm text-gray-500">Service Name</div>
              <div className="font-medium">{data?.service_name}</div>
            </div>
          </div>
        </div>
      </div>
      
      {data?.description && (
        <div className="description-container mb-4 p-4 bg-gray-50 rounded-lg border border-gray-200">
          <h3 className="text-md font-semibold mb-2">Description</h3>
          <ViewTemplate template={data?.description}/>
        </div>
      )}
      
      {renderFileContent()}
    </Modal>
  );
};

export default ViewLabResults;
