import React, { useEffect, useState } from "react";
import { Card, Empty, Spin, Badge, Tooltip, Tag } from "antd";
import AllergiesModal from "./components/AllergiesModal";
import { FaPills, FaAppleAlt, FaTree, FaExclamationTriangle } from "react-icons/fa";

const RecordedAllergies = () => {
  const [userData, setUserData] = useState(null);
  const [showAllergiesModal, setShowAllergiesModal] = useState({});
  const [loading, setLoading] = useState(true);

  const handleAllergyModal = (record) => {
    setShowAllergiesModal({
      open: true,
      selectedItem: record,
    });
  };

  const handleCancel = () => {
    setShowAllergiesModal(null);
  };

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userDataObject = JSON.parse(userDataString);
      setUserData(userDataObject);
    }
    // Simulate loading
    setTimeout(() => setLoading(false), 500);
  }, []);

  const drugAllergies = userData?.allergies?.filter((item) => item?.drug) || [];
  const foodAllergies = userData?.allergies?.filter((item) => item?.food) || [];
  const environmentAllergies = userData?.allergies?.filter(
    (item) => item?.environment
  ) || [];
  const otherAllergies = userData?.allergies?.filter((item) => item?.other) || [];

  const allergyList = [
    { 
      type: "drug", 
      allergies: drugAllergies,
      icon: <FaPills />,
      color: "#f56a00",
      description: "Medications and pharmaceutical substances"
    },
    { 
      type: "food", 
      allergies: foodAllergies,
      icon: <FaAppleAlt />,
      color: "#52c41a",
      description: "Food items and ingredients"
    },
    { 
      type: "environment", 
      allergies: environmentAllergies,
      icon: <FaTree />,
      color: "#1890ff",
      description: "Environmental factors like pollen, dust, etc."
    },
    { 
      type: "other", 
      allergies: otherAllergies,
      icon: <FaExclamationTriangle />,
      color: "#722ed1",
      description: "Other types of allergies"
    },
  ];

  const hasAnyAllergies = allergyList.some(item => item.allergies && item.allergies.length > 0);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Spin size="large" tip="Loading allergies data..." />
      </div>
    );
  }

  return (
    <>
      {!hasAnyAllergies ? (
        <Empty 
          description="No allergies recorded" 
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          className="my-8"
        />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-3 sm:gap-4 lg:gap-6">
          {allergyList.map(({ type, allergies, icon, color, description }) => (
            allergies && allergies.length > 0 && (
              <Card 
                key={type}
                className="allergy-card shadow-sm hover:shadow-md transition-shadow duration-300 rounded-xl overflow-hidden w-full"
                title={
                  <div className="flex items-center flex-wrap gap-2">
                    <Badge count={allergies.length} showZero={false} color={color} className="mr-2" />
                    <span className="text-base sm:text-lg font-medium flex items-center">
                      <span className="mr-2" style={{ color }}>{icon}</span>
                      {`${type.charAt(0).toUpperCase() + type.slice(1)} Allergies`}
                    </span>
                  </div>
                }
                extra={
                  <Tooltip title={description}>
                    <span className="text-xs text-gray-500 cursor-help">Info</span>
                  </Tooltip>
                }
                headStyle={{ 
                  borderBottom: `2px solid ${color}20`,
                  backgroundColor: `${color}05`,
                  padding: '12px 16px'
                }}
                bodyStyle={{ 
                  padding: '12px 16px',
                  maxHeight: '200px',
                  overflowY: 'auto'
                }}
              >
                <div className="flex flex-wrap gap-2">
                  {allergies.map((allergy) => (
                    <Tag
                      key={allergy?.id}
                      color={color}
                      className="m-0 py-1 px-2 sm:px-3 text-xs sm:text-sm cursor-pointer hover:opacity-80 transition-opacity whitespace-normal"
                      onClick={() => handleAllergyModal(allergy)}
                    >
                      {allergy?.allergy_value}
                    </Tag>
                  ))}
                </div>
              </Card>
            )
          ))}
        </div>
      )}

      <AllergiesModal
        open={showAllergiesModal?.open}
        selectedItem={showAllergiesModal?.selectedItem}
        onClose={handleCancel}
      />
    </>
  );
};

export default RecordedAllergies;
