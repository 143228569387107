import React, { useState, useEffect } from "react";
import { Table, Typography, Modal, Button, Tooltip, message } from "antd";
import { Currency } from "../../utils/Utils";
import DateTimeFormat from "../../components/DateTimeFormat/DateTimeFormat";
import { useMediaQuery } from "react-responsive";
import * as XLSX from 'xlsx';
import { 
  FaFileExcel, 
  FaFlask, 
  FaInfoCircle, 
  FaLightbulb,
  FaCalendarAlt,
  FaUserMd,
  FaTag,
  FaMoneyBillWave
} from "react-icons/fa";

const Labs = () => {
  const [userData, setUserData] = useState(null);
  const [viewData, setViewData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [lastExportedFile, setLastExportedFile] = useState(null);
  
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  useEffect(() => {
    const userDataString = sessionStorage.getItem('userData');
    if (userDataString) {
      const userDataObject = JSON.parse(userDataString);
      if (userDataObject.ordered_labs) {
        userDataObject.ordered_labs.sort((a, b) => new Date(b.date_added) - new Date(a.date_added));
      }
      setUserData(userDataObject);
    }
  }, []);

  const getColumns = () => {
    return [
      {
        title: "#",
        render: (_, record, index) => index + 1,
        align: "center",
        width: isMobile ? 40 : 50,
      },
      {
        title: "Lab Order ID",
        dataIndex: "lab_id", 
        key: "lab_id",
        width: isMobile ? 120 : (isTablet ? 150 : 180),
        ellipsis: true,
      },
      {
        title: "Ordered Labs",
        dataIndex: "lab_name",
        key: "lab_name",
        width: isMobile ? 150 : (isTablet ? 180 : 'auto'),
        ellipsis: true,
        render: (labNames, record) => {
          if (labNames && labNames?.length > 0) {
            const truncatedNames = labNames?.slice(0, 3).map(lab => lab.lab_name);
            const displayNames = truncatedNames.join(", ");
            const fullNames = labNames.map(lab => lab.lab_name).join(", ");
            return (
              <Tooltip title={fullNames}>
                <span>{labNames?.length > 3 ? displayNames + "..." : displayNames}</span>
              </Tooltip>
            );
          } else {
            return "N/A";
          }
        },
      },
      {
        title: "Order Type",
        dataIndex: "lab_id",
        key: "lab_id",
        align: 'center',
        width: isMobile ? 100 : 120,
        render: (lab_id) =>
          lab_id.includes("LAB_EX") ? (
            <span className="bg-green-600 px-2 py-[2px] text-white text-xs rounded-md">
              External
            </span>
          ) : (
            <span className="bg-[#3484F0] px-2 py-[2px] text-white text-xs rounded-md">
              In-House
            </span>
          ),
      },
      {
        title: "Provider",
        dataIndex: "added_by",
        key: "added_by",
        align: "center",
        width: isMobile ? 120 : (isTablet ? 150 : 180),
        ellipsis: true,
        render: (text) => (
          <Tooltip title={text}>
            <span>{text}</span>
          </Tooltip>
        ),
      },
      {
        dataIndex: "date_added",
        key: "date_added",
        title: "Date",
        width: isMobile ? 100 : 120,
        align: 'center',
        sorter: (a, b) => {
          const dateA = new Date(a.date_added);
          const dateB = new Date(b.date_added);
          return dateA - dateB;
        },
        render: (text) => (
          <Tooltip title={<DateTimeFormat date={text} type="time" />}>
            <span>{text ? <DateTimeFormat date={text} type="date" /> : ""}</span>
          </Tooltip>
        ),
      },
      {
        dataIndex: "action",
        key: "action",
        align: 'center',
        width: isMobile ? 100 : 120,
        title: "Action",
        fixed: isMobile ? 'right' : undefined,
        render: (text, record) => (
          <Button type="primary" size="small" onClick={() => handleView(record)}>
            {isMobile ? "View" : "View Order"}
          </Button>
        ),
      },
    ];
  };

  const handleView = (record) => {
    setViewData(record);
    setVisible(true);
  };

  // Function to export data to Excel
  const exportToExcel = () => {
    try {
      if (!userData?.ordered_labs || userData.ordered_labs.length === 0) {
        message.warning('No lab order data available to export');
        return;
      }

      setExporting(true);
      
      setTimeout(() => {
        try {
          // Create a clean dataset for export
          const exportData = userData.ordered_labs.map(record => {
            const labNames = record.lab_name?.map(lab => lab.lab_name).join(", ") || "N/A";
            const orderType = record.lab_id.includes("LAB_EX") ? "External" : "In-House";
            
            return {
              "Lab Order ID": record.lab_id,
              "Ordered Labs": labNames,
              "Order Type": orderType,
              "Provider": record.added_by,
              "Date": new Date(record.date_added).toLocaleString(),
            };
          });

          // Create a worksheet
          const worksheet = XLSX.utils.json_to_sheet(exportData);
          
          // Set column widths
          const columnWidths = [
            { wch: 20 }, // Lab Order ID
            { wch: 40 }, // Ordered Labs
            { wch: 15 }, // Order Type
            { wch: 25 }, // Provider
            { wch: 20 }, // Date
          ];
          worksheet['!cols'] = columnWidths;
          
          // Create a workbook
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Lab Orders");
          
          // Generate filename with date
          const date = new Date();
          const dateStr = date.toISOString().split('T')[0]; // YYYY-MM-DD format
          const fileName = `Lab_Orders_${dateStr}.xlsx`;
          
          // Export the file
          XLSX.writeFile(workbook, fileName);
          
          setLastExportedFile(fileName);
          message.success(`Lab order data exported successfully as ${fileName}`);
        } catch (error) {
          console.error('Export error:', error);
          message.error('Failed to export data. Please try again.');
        } finally {
          setExporting(false);
        }
      }, 800);
    } catch (error) {
      console.error('Export error:', error);
      message.error('Failed to export data. Please try again.');
      setExporting(false);
    }
  };

  return (
    <div className="lab-orders-container">
      <div className="flex justify-between items-center mb-4">
        <Typography.Title level={4} className="mb-0">
          Ordered Labs
        </Typography.Title>
        <Button 
          type="default" 
          icon={<FaFileExcel className="text-green-600" />} 
          onClick={exportToExcel}
          disabled={!userData?.ordered_labs || userData.ordered_labs.length === 0 || exporting}
          loading={exporting}
          className="export-excel-button"
        >
          {exporting ? 'Exporting...' : 'Export to Excel'}
        </Button>
      </div>
      
      <div className="mb-4 p-3 bg-gray-50 rounded-lg border border-gray-100">
        <div className="flex items-center text-gray-600">
          <FaInfoCircle className="mr-2 text-blue-500" />
          <span>
            {userData?.ordered_labs?.length || 0} lab order{(userData?.ordered_labs?.length !== 1) ? 's' : ''} found. 
            Click on column headers to sort. Use the "View Order" button to see details.
          </span>
        </div>
        {lastExportedFile && (
          <div className="mt-2 text-xs text-gray-500 flex items-center">
            <FaFileExcel className="mr-1 text-green-600" /> 
            Last exported: <span className="font-medium ml-1">{lastExportedFile}</span>
          </div>
        )}
      </div>
      
      <div className="table-responsive">
        <Table
          size={isMobile ? "small" : "middle"}
          columns={getColumns()}
          dataSource={userData?.ordered_labs || []}
          rowKey={(record) => record?.lab_id}
          pagination={{
            pageSize: 10,
            responsive: true,
            showSizeChanger: !isMobile,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
          scroll={{ x: isMobile ? 600 : 800 }}
          loading={!userData}
          className="responsive-table vitals-data-table"
          rowClassName={(record, index) => index % 2 === 0 ? 'bg-gray-50' : ''}
        />
      </div>

      <Modal 
        title={
          <div className="flex items-center">
            <div className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-100 mr-3">
              <FaFlask className="text-blue-500 text-xl" />
            </div>
            <div>
              <span className="text-xl font-semibold">Lab Order Details</span>
              <p className="text-sm text-gray-500 mt-1">
                Order ID: {viewData?.lab_id}
              </p>
            </div>
          </div>
        }
        open={visible} 
        footer={[
          <Button key="close" type="primary" onClick={() => setVisible(false)}>
            Close
          </Button>
        ]}
        onCancel={() => setVisible(false)}
        width={isMobile ? "95%" : 650}
        centered
        className="vitals-data-modal"
      >
        <div className="mb-4 p-3 bg-blue-50 rounded-lg border border-blue-100">
          <div className="flex items-start">
            <FaLightbulb className="mr-2 text-yellow-500 mt-1 flex-shrink-0" />
            <span className="text-gray-600">
              <strong>About your lab orders:</strong> Follow any preparation instructions provided by your healthcare provider. 
              Results will be available after processing.
            </span>
          </div>
        </div>
        
        <div className="lab-order-details mb-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex items-center">
              <div className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-100 mr-2">
                <FaUserMd className="text-blue-500" />
              </div>
              <div>
                <div className="text-sm text-gray-500">Provider</div>
                <div className="font-medium">{viewData?.added_by}</div>
              </div>
            </div>
            
            <div className="flex items-center">
              <div className="flex items-center justify-center w-8 h-8 rounded-full bg-green-100 mr-2">
                <FaCalendarAlt className="text-green-500" />
              </div>
              <div>
                <div className="text-sm text-gray-500">Date Added</div>
                <div className="font-medium">
                  <DateTimeFormat date={viewData?.date_added} type="datetime"/>
                </div>
              </div>
            </div>
            
            <div className="flex items-center">
              <div className="flex items-center justify-center w-8 h-8 rounded-full bg-purple-100 mr-2">
                <FaTag className="text-purple-500" />
              </div>
              <div>
                <div className="text-sm text-gray-500">Lab Type</div>
                <div className="font-medium">
                  {viewData?.lab_id?.includes("LAB_EX") ? 'External' : 'In-House'}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ordered-labs-list">
          <h3 className="text-lg font-semibold mb-3">Ordered Tests</h3>
          {viewData?.lab_name?.map((lab, index) => (
            <div className="mb-4 bg-gray-50 p-4 rounded-lg border border-gray-100 hover:shadow-md transition-shadow duration-300" key={index}>
              <h4 className="text-md font-semibold text-blue-600 mb-2">{lab.lab_name}</h4>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {lab.lab_instructions && (
                  <div className="col-span-2">
                    <p className="mb-2">
                      <span className="font-medium text-gray-700">Instructions: </span>
                      <span>{lab.lab_instructions}</span>
                    </p>
                  </div>
                )}
                
                {lab.lab_diagnosis && (
                  <div className="col-span-2">
                    <p className="mb-2">
                      <span className="font-medium text-gray-700">Diagnosis: </span>
                      <span>{lab.lab_diagnosis}</span>
                    </p>
                  </div>
                )}
                
                {lab.lab_price && (
                  <div>
                    <p className="mb-1 flex items-center">
                      <FaMoneyBillWave className="text-green-600 mr-1" />
                      <span className="font-medium text-gray-700">Price: </span>
                      <span className="ml-1"><Currency value={lab.lab_price}/></span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default Labs;
