import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Tooltip, Tag, Space, message } from "antd";
import axios from "axios";
import ViewLabResults from "./components/ViewLabResults";
import DateTimeFormat from "../../components/DateTimeFormat/DateTimeFormat";
import { useMediaQuery } from "react-responsive";
import * as XLSX from 'xlsx';
import { 
  FaFileExcel, 
  FaVial, 
  FaInfoCircle, 
  FaLightbulb
} from "react-icons/fa";

const { Title } = Typography;

const LabResults = () => {
  const [userData, setUserData] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const API_Path = process.env.REACT_APP_API_URL || 'http://localhost:8000';
  const [view, setView] = useState({
    viewState: false,
    viewData: [],
  });
  const [exporting, setExporting] = useState(false);
  const [lastExportedFile, setLastExportedFile] = useState(null);
  
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  useEffect(() => {
    const userDataString = sessionStorage.getItem('userData');
    if (userDataString) {
      const userDataObject = JSON.parse(userDataString);
      if (userDataObject.lab_uploaded_results) {
        userDataObject.lab_uploaded_results.sort((a, b) => new Date(b.date_time) - new Date(a.date_time));
      }
      setUserData(userDataObject);
    }
  }, []);

  const getColumns = () => {
    // Base columns for all screen sizes
    const baseColumns = [
      {
        key: "#",
        title: "#",
        render: (_, __, index) => index + 1,
        width: isMobile ? 40 : 50,
      },
      {
        key: 1,
        title: "Lab Order ID",
        dataIndex: "lab_order_id",
        width: isMobile ? 120 : (isTablet ? 150 : 180),
        ellipsis: true,
      },
      {
        key: 2,
        title: "Service Name",
        dataIndex: "service_name",
        ellipsis: true,
        width: isMobile ? 150 : (isTablet ? 180 : 'auto'),
      },
      {
        key: 4,
        title: "Uploaded By",
        dataIndex: "added_by",
        width: isMobile ? 120 : (isTablet ? 150 : 180),
        ellipsis: true,
        render: (text) => (
          <Tooltip title={text}>
            <span>{text}</span>
          </Tooltip>
        ),
      },
      {
        key: 5,
        title: "Date",
        dataIndex: "date_time",
        align: 'center',
        width: isMobile ? 100 : 120,
        sorter: (a, b) => {
          const dateA = new Date(a.date_time);
          const dateB = new Date(b.date_time);
          return dateA - dateB;
        },
        render: (text) => (
          <Tooltip title={<DateTimeFormat date={text} type="time" />}>
            <span>{text ? <DateTimeFormat date={text} type="date" /> : ""}</span>
          </Tooltip>
        ),
      },
      {
        key: 6,
        title: "Action",
        align: "center",
        width: isMobile ? 100 : 120,
        fixed: isMobile ? 'right' : undefined,
        render: (text, record) => (
          <Button type='primary' size="small" onClick={() => handleView(record)}>
            {isMobile ? "View" : "View Results"}
          </Button>
        ),
      },
    ];

    return baseColumns;
  };

  const handleView = async (record) => {
    await handleFile(record?.uploaded_file_encrypted_name);
    setView({
      ...view,
      viewState: true,
      viewData: record,
    });
  };

  const handleFile = async (record) => {
    setFileLoading(true);
    try {
      const response = await axios.get(
        `${API_Path}/patient/view-file/?filename=${record}&mrn_number=${userData?.patient_info?.mrn_number}&password=${userData?.patient_info?.password}`
      );
      if (response.status === 200) {
        const fileContent = response.data;
        setFileData(fileContent);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFileLoading(false);
    }
  };

  const closeView = () => {
    setView({
      ...view,
      viewState: false,
      viewData: []
    });
  };

  // Function to export data to Excel
  const exportToExcel = () => {
    try {
      if (!userData?.lab_uploaded_results || userData.lab_uploaded_results.length === 0) {
        message.warning('No lab results data available to export');
        return;
      }

      setExporting(true);
      
      setTimeout(() => {
        try {
          // Create a clean dataset for export
          const exportData = userData.lab_uploaded_results.map(record => {
            return {
              "Lab Order ID": record.lab_order_id,
              "Service Name": record.service_name,
              "Uploaded By": record.added_by,
              "Date": new Date(record.date_time).toLocaleString(),
              "Filename": record.filename,
            };
          });

          // Create a worksheet
          const worksheet = XLSX.utils.json_to_sheet(exportData);
          
          // Set column widths
          const columnWidths = [
            { wch: 20 }, // Lab Order ID
            { wch: 30 }, // Service Name
            { wch: 25 }, // Uploaded By
            { wch: 20 }, // Date
            { wch: 30 }, // Filename
          ];
          worksheet['!cols'] = columnWidths;
          
          // Create a workbook
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Lab Results");
          
          // Generate filename with date
          const date = new Date();
          const dateStr = date.toISOString().split('T')[0]; // YYYY-MM-DD format
          const fileName = `Lab_Results_${dateStr}.xlsx`;
          
          // Export the file
          XLSX.writeFile(workbook, fileName);
          
          setLastExportedFile(fileName);
          message.success(`Lab results data exported successfully as ${fileName}`);
        } catch (error) {
          console.error('Export error:', error);
          message.error('Failed to export data. Please try again.');
        } finally {
          setExporting(false);
        }
      }, 800);
    } catch (error) {
      console.error('Export error:', error);
      message.error('Failed to export data. Please try again.');
      setExporting(false);
    }
  };

  return (
    <div className="lab-results-container">
      <div className="flex justify-between items-center mb-4">
        <Title level={4} className="mb-0">
          Lab Results
        </Title>
        <Button 
          type="default" 
          icon={<FaFileExcel className="text-green-600" />} 
          onClick={exportToExcel}
          disabled={!userData?.lab_uploaded_results || userData.lab_uploaded_results.length === 0 || exporting}
          loading={exporting}
          className="export-excel-button"
        >
          {exporting ? 'Exporting...' : 'Export to Excel'}
        </Button>
      </div>
      
      <div className="mb-4 p-3 bg-gray-50 rounded-lg border border-gray-100">
        <div className="flex items-center text-gray-600">
          <FaInfoCircle className="mr-2 text-blue-500" />
          <span>
            {userData?.lab_uploaded_results?.length || 0} lab result{(userData?.lab_uploaded_results?.length !== 1) ? 's' : ''} found. 
            Click on column headers to sort. Use the "View Results" button to see details.
          </span>
        </div>
        {lastExportedFile && (
          <div className="mt-2 text-xs text-gray-500 flex items-center">
            <FaFileExcel className="mr-1 text-green-600" /> 
            Last exported: <span className="font-medium ml-1">{lastExportedFile}</span>
          </div>
        )}
      </div>
      
      <div className="table-responsive">
        <Table
          columns={getColumns()}
          dataSource={userData?.lab_uploaded_results || []}
          size={isMobile ? "small" : "middle"}
          pagination={{
            pageSize: 10,
            responsive: true,
            showSizeChanger: !isMobile,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
          scroll={{ x: isMobile ? 600 : 800 }}
          rowKey={(record) => record.lab_order_id || Math.random().toString(36).substring(2, 9)}
          loading={!userData}
          className="responsive-table vitals-data-table"
          rowClassName={(record, index) => index % 2 === 0 ? 'bg-gray-50' : ''}
        />
      </div>
      
      <ViewLabResults 
        visible={view?.viewState} 
        onClose={closeView} 
        data={view?.viewData} 
        fileData={fileData} 
        loading={fileLoading}
        className="vitals-data-modal"
      />
    </div>
  );
};

export default LabResults;
