import React from "react";
import { Button, Modal, Descriptions, Tag, Alert, Divider } from "antd";
import DateFormat from "../../../components/DateTimeFormat/DateFormat";
import { FaCalendarAlt, FaExclamationTriangle, FaUserMd, FaAllergies } from "react-icons/fa";

const AllergiesModal = ({ open, selectedItem, onClose }) => {
  const handleCancel = () => {
    onClose();
  };

  const getAllergyType = () => {
    if (!selectedItem) return null;
    
    if (selectedItem.drug) return { type: "Drug", color: "#f56a00" };
    if (selectedItem.food) return { type: "Food", color: "#52c41a" };
    if (selectedItem.environment) return { type: "Environment", color: "#1890ff" };
    if (selectedItem.other) return { type: "Other", color: "#722ed1" };
    
    return { type: "Unknown", color: "#d9d9d9" };
  };

  const allergyType = getAllergyType();

  return (
    <Modal
      title={
        <div className="flex items-center">
          <FaAllergies className="mr-2 text-red-500" />
          <span>Allergy Details</span>
        </div>
      }
      open={open}
      onCancel={handleCancel}
        footer={[
          <Button key="close" type="primary" onClick={handleCancel}>
            Close
          </Button>
        ]}
      width={500}
      centered
      className="allergy-modal"
    >
      {selectedItem && (
        <div className="pt-2">
          <div className="mb-4">
            {allergyType && (
              <Tag color={allergyType.color} className="text-sm px-3 py-1">
                {allergyType.type} Allergy
              </Tag>
            )}
          </div>
          
          <Alert
            message={selectedItem?.allergy_value}
            description={
              selectedItem?.description ? (
                <div className="mt-2">
                  <strong>Reaction:</strong> {selectedItem.description}
                </div>
              ) : "No reaction details available"
            }
            type="warning"
            showIcon
            icon={<FaExclamationTriangle />}
            className="mb-4"
          />

          <Divider className="my-4" />
          
          <Descriptions 
            column={1} 
            bordered 
            size="small"
            className="rounded-lg overflow-hidden"
          >
            <Descriptions.Item 
              label={
                <div className="flex items-center">
                  <FaCalendarAlt className="mr-2 text-blue-500" />
                  <span>Start Date</span>
                </div>
              }
            >
              {selectedItem?.start_date ? (
                <DateFormat date={selectedItem?.start_date} />
              ) : (
                <span className="text-gray-500 italic">No date recorded</span>
              )}
            </Descriptions.Item>
            
            <Descriptions.Item 
              label={
                <div className="flex items-center">
                  <FaCalendarAlt className="mr-2 text-green-500" />
                  <span>End Date</span>
                </div>
              }
            >
              {selectedItem?.end_date ? (
                <DateFormat date={selectedItem?.end_date} />
              ) : (
                <span className="text-gray-500 italic">No date recorded</span>
              )}
            </Descriptions.Item>
            
            <Descriptions.Item 
              label={
                <div className="flex items-center">
                  <FaUserMd className="mr-2 text-purple-500" />
                  <span>Added By</span>
                </div>
              }
            >
              {selectedItem?.added_by || "Unknown"}
            </Descriptions.Item>
          </Descriptions>
        </div>
      )}
    </Modal>
  );
};

export default AllergiesModal;
