import React, { useState, useEffect } from "react";
import { Avatar, Button, Dropdown, Modal, Form, Input, message, Tooltip } from "antd";
import { LockOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { validatePassword } from "../../utils/validator";
import axios from "axios";

const PatientProfile = ({ collapsed, toggleSidebar, showClinicInfo = true }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [userData, setUserData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const API_Path = process.env.REACT_APP_API_URL || "http://localhost:8000";

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userDataObject = JSON.parse(userDataString);
      setUserData(userDataObject);
    }
  }, []);

  const items = [
    {
      label: "Update Password",
      key: "0",
      icon: <LockOutlined />,
    },
    {
      label: "Logout",
      key: "1",
      icon: <LogoutOutlined />,
    },
  ];

  const onClick = async ({ key }) => {
    if (key === "0") {
      setShowModal(true);
    }
    if (key === "1") {
      sessionStorage.clear();
      navigate("/");
    }
  };

  const updatePassword = async (values) => {
    setLoading(true);
    const data = {
      password: values?.password,
    };
    try {
      const response = await axios.patch(
        `${API_Path}/patient/update-patient-password/${userData?.patient_info?.id}/`,
        data
      );
      if (response.status === 200) {
        message.success("Password Updated Successfully");
        handleCancel();
      }
    } catch (error) {
      message.error("Something Went Wrong. Contact the Clinic Management");
    } finally {
      setLoading(false);
    }
  };

  const getInitials = () => {
    if (!userData?.patient_info?.first_name) return "U";
    
    const firstName = userData?.patient_info?.first_name?.[0] || "";
    const lastName = userData?.patient_info?.last_name?.[0] || "";
    
    return (firstName + lastName).toUpperCase();
  };

  return (
    <>
      <div className="flex justify-between items-center w-full">
        {showClinicInfo && userData?.office_logo && (
          <div className="flex items-center ml-2">
            <img
              src={userData?.office_logo}
              className="h-10 w-auto mr-2"
              alt="clinic_logo"
            />
            <h1 className="text-lg font-medium hidden md:block">{userData && userData?.clinic_name}</h1>
          </div>
        )}
        <Dropdown
          trigger={["click"]}
          menu={{ items: items, onClick }}
          open={isDropdownVisible}
          onOpenChange={setDropdownVisible}
          placement="bottomRight"
        >
          <div className="flex items-center px-3 py-2 rounded-full cursor-pointer hover:bg-gray-50 transition-colors">
            <div className="mr-2 text-right hidden sm:block">
              <Tooltip title={`${userData?.patient_info?.first_name || ""} ${userData?.patient_info?.middle_name || ""} ${userData?.patient_info?.last_name || ""}`}>
                <p className="text-sm font-semibold truncate max-w-[120px] md:max-w-[200px]">
                  {userData?.patient_info?.first_name || ""}{" "}
                  {userData?.patient_info?.last_name || ""}
                </p>
              </Tooltip>
              <p className="text-xs text-gray-500">Patient</p>
            </div>
            <Avatar
              shape="circle"
              className="w-10 h-10 flex justify-center items-center shadow-sm"
              src={userData?.patient_info?.profile_picture || null}
              icon={!userData?.patient_info?.profile_picture && <UserOutlined />}
            >
              {!userData?.patient_info?.profile_picture && getInitials()}
            </Avatar>
          </div>
        </Dropdown>
      </div>

      <Modal
        title="Update Password"
        open={showModal}
        footer={null}
        onCancel={handleCancel}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
        width={376}
        centered
        maskClosable={false}
        className="responsive-modal"
      >
        <Form layout="vertical" form={form} onFinish={updatePassword}>
          <Form.Item
            label="New Password"
            name="password"
            rules={[
              { required: true, message: "Please input your password!" },
              { validator: validatePassword },
            ]}
          >
            <Input.Password
              placeholder="Please enter your Password here"
              minLength={8}
              prefix={<LockOutlined className="text-gray-400" />}
              className="rounded-lg"
            />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label="Confirm New Password"
            hasFeedback
            dependencies={["password"]}
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Passwords do not match!");
                },
              }),
            ]}
          >
            <Input.Password 
              placeholder="Confirm Password" 
              prefix={<LockOutlined className="text-gray-400" />}
              className="rounded-lg"
            />
          </Form.Item>

          <div className="flex justify-end mt-4">
            <Button onClick={handleCancel} className="mr-2">
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Update
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default PatientProfile;
