import { message } from 'antd';
import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [appLogoData, setAppLogoData] = useState(null);
  const API_Path = process.env.REACT_APP_API_URL || 'http://localhost:8000';

  useEffect(() => {
    // Check if user data exists in session storage on component mount
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      try {
        const parsedUserData = JSON.parse(userDataString);
        // Verify the required clinic information
        const hasRequiredInfo = parsedUserData && 
          parsedUserData.patient_info;

        if (hasRequiredInfo) {
          setUserData(parsedUserData);
        } else {
          // Clear invalid session data
          sessionStorage.removeItem("userData");
          setUserData(null);
        }
      } catch (error) {
        console.error("Error parsing user data:", error);
        sessionStorage.removeItem("userData");
        setUserData(null);
      }
    }
    setLoading(false);
  }, []);

  const setUser = (data) => {
    // Verify the required clinic information before setting user data
    const hasRequiredInfo = data && 
      data.clinic_name === "Abdullah Clinic" && 
      data.facility_time_zone === "Asia/Karachi" && 
      data.date_format === "en-US" && 
      data.time_format === "h12" && 
      data.office_logo && 
      data.patient_info;

    if (hasRequiredInfo) {
      setUserData(data);
      sessionStorage.setItem("userData", JSON.stringify(data));
    } else {
      setUserData(null);
      sessionStorage.removeItem("userData");
    }
  };

  const getAppLogo = async () => {
    try {
      const response = await axios.get(`${API_Path}/administration/retrieve-application-images/`);
      if (response.status === 200) {
        setAppLogoData(response.data.data);
      } else if (response.status === 400){
        setAppLogoData(null);
      }
    } catch (error) {
      console.error("Error fetching app logo:", error);
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    sessionStorage.removeItem("userData");
    setUserData(null);
    window.location.href = "/";
  };

  return (
    <UserContext.Provider value={{ 
      userData, 
      setUser, 
      appLogoData, 
      getAppLogo, 
      loading,
      logout 
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
