import React, { useEffect, useState } from "react";
import { Card, Col, Row, Skeleton, Badge, Modal, Descriptions, Divider, Button, Tooltip } from "antd";
import RecordedVitals from "./RecordedVitals";
import RecordedAllergies from "./RecordedAllergies";
import { FaHeartbeat, FaWeight, FaStethoscope, FaAllergies, FaInfoCircle, FaHistory } from "react-icons/fa";
import { TbDropCircle, TbRuler } from "react-icons/tb";
import { FaTemperatureHalf } from "react-icons/fa6";
import { formatDate } from "../../utils/Utils";
import { OxygenSaturationLabels, TemperatureLabels } from "../../components/Status/StatusLabels";

const VitalSigns = () => {
  const [userData, setUserData] = useState(null);
  const [latestVitals, setLatestVitals] = useState({
    heart_rate: null,
    blood_pressure: null,
    oxygen: null,
    body_temp: null,
    height: null,
    weight: null,
    date_added: null,
  });
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVital, setSelectedVital] = useState(null);

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userDataObject = JSON.parse(userDataString);
      if (
        userDataObject &&
        userDataObject.vitals &&
        userDataObject.vitals.length > 0
      ) {
        const latestUserData =
          userDataObject.vitals[userDataObject.vitals.length - 1];
        setLatestVitals({
          heart_rate: latestUserData.heart_rate,
          blood_pressure: latestUserData.blood_pressure,
          oxygen: latestUserData.oxygen,
          body_temp: latestUserData.body_temp,
          height: latestUserData.height,
          weight: latestUserData.weight,
          date_added: latestUserData.date_time,
        });
      }
      setUserData(userDataObject);
      // Simulate loading
      setTimeout(() => setLoading(false), 600);
    }
  }, []);

  const vitals = [
    {
      name: "Heart Rate",
      value: latestVitals?.heart_rate || "No Record Added",
      icon: <FaHeartbeat />,
      color: "#FF4D4F",
      unit: "bpm",
      description: "Heart rate is the number of times your heart beats per minute. A normal resting heart rate for adults ranges from 60 to 100 beats per minute.",
      normalRange: "60-100 bpm",
      detailIcon: <FaHeartbeat size={24} />,
    },
    {
      name: "Blood Pressure",
      value: latestVitals?.blood_pressure || "No Record Added",
      icon: <FaStethoscope />,
      color: "#1890FF",
      unit: "mmHg",
      description: "Blood pressure is the pressure of circulating blood against the walls of blood vessels. It is expressed as systolic/diastolic pressure.",
      normalRange: "Less than 120/80 mmHg",
      detailIcon: <FaStethoscope size={24} />,
    },
    {
      name: "O2 Saturation",
      value: OxygenSaturationLabels(latestVitals?.oxygen),
      rawValue: latestVitals?.oxygen,
      icon: <TbDropCircle />,
      color: "#52C41A",
      unit: "%",
      description: "Oxygen saturation is a measure of how much oxygen your red blood cells are carrying as a percentage of the maximum they could carry.",
      normalRange: "95-100%",
      detailIcon: <TbDropCircle size={24} />,
    },
    {
      name: "Temperature",
      value: TemperatureLabels(latestVitals?.body_temp),
      rawValue: latestVitals?.body_temp,
      icon: <FaTemperatureHalf />,
      color: "#FADB14",
      unit: "°C",
      description: "Body temperature is a measure of the body's ability to generate and get rid of heat. The normal body temperature is 98.6°F (37°C).",
      normalRange: "36.5-37.5°C (97.7-99.5°F)",
      detailIcon: <FaTemperatureHalf size={24} />,
    },
    {
      name: "Height",
      value: latestVitals?.height
        ? `${latestVitals.height}`
        : "No Record Added",
      rawValue: latestVitals?.height,
      icon: <TbRuler />,
      color: "#8E44AD",
      unit: "cm",
      description: "Height is a measurement of vertical distance, either vertical extent (how tall something or someone is) or vertical position (how high a point is).",
      normalRange: "Varies by age, gender, and genetics",
      detailIcon: <TbRuler size={24} />,
    },
    {
      name: "Weight",
      value: latestVitals?.weight
        ? `${latestVitals.weight}`
        : "No Record Added",
      rawValue: latestVitals?.weight,
      icon: <FaWeight />,
      color: "#E67E22",
      unit: "kg",
      description: "Weight is a measurement of the force exerted on an object due to gravity. In medical contexts, it's used to track health and body composition.",
      normalRange: "Varies by height, age, gender, and body composition",
      detailIcon: <FaWeight size={24} />,
    },
  ];

  const handleVitalClick = (vital) => {
    setSelectedVital(vital);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedVital(null);
  };

  const renderVitalDetailModal = () => {
    if (!selectedVital) return null;

    return (
      <Modal
        title={
          <div className="flex items-center">
            <div 
              className="p-2 rounded-full mr-3"
              style={{ backgroundColor: `${selectedVital.color}20`, color: selectedVital.color }}
            >
              {selectedVital.detailIcon}
            </div>
            <span>{selectedVital.name} Details</span>
          </div>
        }
        open={modalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button 
            key="history" 
            type="default" 
            icon={<FaHistory />}
            onClick={() => {
              handleModalClose();
              // Scroll to the historical vitals section
              document.querySelector('.vitals-history-card').scrollIntoView({ 
                behavior: 'smooth',
                block: 'start'
              });
            }}
          >
            View History
          </Button>,
          <Button key="close" type="primary" onClick={handleModalClose}>
            Close
          </Button>,
        ]}
        width={600}
      >
        <Divider />
        <Descriptions bordered column={1} size="middle">
          <Descriptions.Item 
            label={
              <div className="font-medium">Current Value</div>
            }
          >
            <div className="text-xl font-semibold" style={{ color: selectedVital.color }}>
              {selectedVital.value}
            </div>
          </Descriptions.Item>
          
          <Descriptions.Item 
            label={
              <div className="font-medium">Normal Range</div>
            }
          >
            <div className="text-gray-700">{selectedVital.normalRange}</div>
          </Descriptions.Item>
          
          <Descriptions.Item 
            label={
              <div className="font-medium">Description</div>
            }
          >
            <div className="text-gray-700">{selectedVital.description}</div>
          </Descriptions.Item>
          
          <Descriptions.Item 
            label={
              <div className="font-medium">Last Updated</div>
            }
          >
            <div className="text-gray-700">
              {latestVitals?.date_added ? formatDate(latestVitals.date_added) : "N/A"}
            </div>
          </Descriptions.Item>
        </Descriptions>

        <div className="mt-4 bg-blue-50 p-4 rounded-lg">
          <div className="flex items-start">
            <FaInfoCircle className="text-blue-500 mt-1 mr-2" />
            <div className="text-sm text-gray-700">
              This information is based on your most recent vital signs recording. For a complete history of your {selectedVital.name.toLowerCase()} measurements, please check the Historical Vitals section below.
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  if (loading) {
    return (
      <div className="p-6">
        <Skeleton active paragraph={{ rows: 4 }} />
        <div className="mt-8">
          <Skeleton.Button active size="large" shape="round" block />
          <div className="grid grid-cols-2 gap-4 mt-4">
            <Skeleton active paragraph={{ rows: 6 }} />
            <Skeleton active paragraph={{ rows: 6 }} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="vitals-page-container">
      <div className="welcome-section mb-8">
        <h1 className="text-3xl font-semibold p-3 mb-2 rounded-2xl bg-gradient-to-r from-blue-50 to-indigo-50 shadow-sm">
          Welcome back, {userData?.patient_info?.first_name}{" "}
          {userData?.patient_info?.middle_name &&
            userData?.patient_info?.middle_name}{" "}
          {userData?.patient_info?.last_name}
        </h1>
        <h1 className="text-2xl font-semibold text-center bg-blue-100 p-3 mb-6 rounded-2xl shadow-sm">
          Recorded Vitals
        </h1>
      </div>

      <div className="vitals-summary-section mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4">
          {vitals.map((vital, index) => (
            <div key={index} className="w-full">
              <Tooltip title="Click for details">
                <Card
                  className="text-center border border-gray-200 shadow-lg rounded-3xl transition-transform transform hover:scale-105 hover:shadow-2xl hover:border-gray-300 hover:bg-gradient-to-r from-blue-50 to-indigo-100 cursor-pointer"
                  bodyStyle={{ padding: "14px", height: "180px" }}
                  onClick={() => handleVitalClick(vital)}
                >
                  <div
                    className="flex justify-center items-center mb-4 w-16 h-16 rounded-full mx-auto"
                    style={{
                      backgroundColor: `${vital.color}20`,
                      color: vital.color,
                    }}
                  >
                    <div className="text-xl">{vital.icon}</div>
                  </div>
                  <p className="text-md font-bold mb-2 text-gray-700">
                    {vital.name}
                  </p>
                  <p className="text-xl font-semibold text-gray-900">
                    {vital.value}
                  </p>
                </Card>
              </Tooltip>
            </div>
          ))}
        </div>
      </div>

      <div className="vitals-info-section mb-8">
        <div className="flex flex-col md:flex-row justify-between items-center bg-gradient-to-r from-blue-50 to-indigo-50 p-6 rounded-xl shadow-md">
          <h2 className="text-gray-700 font-medium text-lg mb-3 md:mb-0">
            These are your recorded vitals from your most recent visit.
          </h2>
          {latestVitals?.date_added && (
            <div className="flex items-center bg-white px-4 py-2 rounded-lg shadow-sm">
              <span className="text-blue-600 font-medium mr-2">Last Updated:</span>
              <span className="text-gray-600">{formatDate(latestVitals?.date_added)}</span>
            </div>
          )}
        </div>
      </div>

      <div className="vitals-details-section">
        <Row gutter={[24, 24]} className="mt-6">
          <Col xs={24} md={12}>
            <div className="vitals-history-card h-full">
              <div className="bg-white shadow-xl rounded-2xl overflow-hidden h-full border border-gray-100">
                <div className="relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-indigo-700 opacity-90"></div>
                  <div className="relative py-5 px-6">
                    <div className="flex items-center">
                      <div className="flex items-center justify-center w-12 h-12 bg-white rounded-full shadow-md mr-4">
                        <FaHeartbeat className="text-blue-600 text-2xl" />
                      </div>
                      <div>
                        <h1 className="text-2xl font-bold text-white">
                          Historical Vitals
                        </h1>
                        <p className="text-blue-100 text-sm mt-1">View your complete vitals history</p>
                      </div>
                      <div className="ml-auto">
                        <Badge 
                          count="All Records" 
                          className="historical-badge" 
                          style={{ 
                            backgroundColor: 'rgba(255, 255, 255, 0.9)', 
                            color: '#1d4ed8',
                            fontWeight: 'bold',
                            border: 'none',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                          }} 
                        />
                      </div>
                    </div>
                  </div>
                  <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-500"></div>
                </div>
                <div className="p-6">
                  <RecordedVitals />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="allergies-card h-full">
              <div className="bg-white shadow-xl rounded-2xl overflow-hidden h-full border border-gray-100">
                <div className="relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-red-600 to-pink-700 opacity-90"></div>
                  <div className="relative py-5 px-6">
                    <div className="flex items-center">
                      <div className="flex items-center justify-center w-12 h-12 bg-white rounded-full shadow-md mr-4">
                        <FaAllergies className="text-red-600 text-2xl" />
                      </div>
                      <div>
                        <h1 className="text-2xl font-bold text-white">
                          Allergies
                        </h1>
                        <p className="text-red-100 text-sm mt-1">Important health information</p>
                      </div>
                      <div className="ml-auto">
                        <Badge 
                          count="All Records" 
                          className="allergies-badge" 
                          style={{ 
                            backgroundColor: 'rgba(255, 255, 255, 0.9)', 
                            color: '#e11d48',
                            fontWeight: 'bold',
                            border: 'none',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                          }} 
                        />
                      </div>
                    </div>
                  </div>
                  <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-red-400 via-pink-500 to-rose-500"></div>
                </div>
                <div className="p-6">
                  <RecordedAllergies />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      {renderVitalDetailModal()}
    </div>
  );
};

export default VitalSigns;
