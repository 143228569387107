import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button, message, Typography, Tooltip, Card, Checkbox, Divider, Row, Col } from "antd";
import logo from "../../Assets/logo.png";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import "./login.css";
import { 
  EyeInvisibleOutlined, 
  EyeTwoTone, 
  LockOutlined, 
  UserOutlined, 
  SafetyOutlined,
  MedicineBoxOutlined,
  HeartOutlined
} from "@ant-design/icons";
import ReCAPTCHA from "react-google-recaptcha";
import LoadingSpinner from "../../components/LoadingSpinner";

const { Title, Paragraph, Text } = Typography;

const Login = () => {
  const [form] = Form.useForm();
  const captchaRef = useRef(null);
  const navigate = useNavigate();
  const { appLogoData, getAppLogo } = useUser();
  const [loading, setLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [checkingAuth, setCheckingAuth] = useState(true);
  const API_Path = process.env.REACT_APP_API_URL || "http://localhost:8000";

  useEffect(() => {
    getAppLogo();
    
    // Check if user is already authenticated
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      try {
        const userData = JSON.parse(userDataString);
        
        // Check if the user has the required clinic information
        const hasRequiredInfo = userData && userData.patient_info;
        
        if (hasRequiredInfo) {
          // User is already authenticated, redirect to dashboard
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Error parsing user data:", error);
        // Clear invalid session data
        sessionStorage.removeItem("userData");
      }
    }
    setCheckingAuth(false);
  }, [navigate]);

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token); // Save the reCAPTCHA token
  };

  const resetCaptcha = () => {
    setCaptchaToken(""); // Clear the token state
    if (captchaRef.current) {
      captchaRef.current.reset(); // Reset the reCAPTCHA instance
    }
  };

  const validateCaptcha = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/user/verify-captcha/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token: captchaToken }),
      }
    );

    const result = await response.json();
    return result.message;
  };

  const handleLogin = async (values) => {
    setLoading(true);
    if (!captchaToken) {
      message.error("Please complete the CAPTCHA!");
      setLoading(false);
      return;
    }

    const isCaptchaValid = await validateCaptcha();
    if (isCaptchaValid !== "CAPTCHA verified!") {
      message.error("CAPTCHA verification failed. Please try again.");
      resetCaptcha(); // Reset the CAPTCHA
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post(`${API_Path}/patient/login/`, values);
      if (response.status === 200) {
        // Check if the response data has the required clinic information
        const userData = response.data;
        const hasRequiredInfo = userData && userData.patient_info;

        if (hasRequiredInfo) {
          sessionStorage.setItem("userData", JSON.stringify(userData));
          message.success("Login successful!");
          navigate("/dashboard");
        } else {
          message.error("Access denied. Your account doesn't have the required permissions.");
        }
      } else {
        message.error("Login failed. Please check your credentials.");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        message.error("Invalid credentials. Please try again.");
      } else {
        message.error("An error occurred during login. Please try again later.");
      }
      console.error("Login error:", error);
    } finally {
      setLoading(false);
    }
  };

  if (checkingAuth) {
    return <LoadingSpinner />;
  }

  return (
    <div className="login-page">
      <div className="login-container">
        <Row className="login-row">
          <Col xs={0} sm={0} md={12} lg={14} xl={16} className="login-left-panel">
            <div className="login-left-content">
              <div className="login-brand">
                <HeartOutlined className="login-brand-icon" />
                <h1>Patient Portal</h1>
              </div>
              <div className="login-features">
                <div className="feature-item">
                  <SafetyOutlined className="feature-icon" />
                  <div className="feature-text">
                    <h3>Secure Access</h3>
                    <p>Protected health information with industry-leading security</p>
                  </div>
                </div>
                <div className="feature-item">
                  <MedicineBoxOutlined className="feature-icon" />
                  <div className="feature-text">
                    <h3>Complete Records</h3>
                    <p>Access medical history, lab results, and prescriptions</p>
                  </div>
                </div>
                <div className="feature-item">
                  <HeartOutlined className="feature-icon" />
                  <div className="feature-text">
                    <h3>Health Monitoring</h3>
                    <p>Track vital signs and health metrics over time</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={10} xl={8} className="login-right-panel">
            <Card className="login-card" bordered={false}>
              <div className="login-header">
                <img
                  src={appLogoData?.icon || logo}
                  alt="Patient Portal Logo"
                  className="login-logo"
                />
                <Title level={3} className="login-title">Welcome Back</Title>
                <Paragraph className="login-subtitle">
                  Sign in to access your health information
                </Paragraph>
              </div>
              
              <Form
                form={form}
                onFinish={handleLogin}
                layout="vertical"
                className="login-form"
                size="middle"
                requiredMark={false}
              >
                <Form.Item
                  name="mrn_number"
                  rules={[{ required: true, message: "Please enter your MRN number" }]}
                  label={<span className="login-label">MRN Number</span>}
                >
                  <Input
                    prefix={<UserOutlined className="login-icon" />}
                    placeholder="Enter your MRN number"
                    className="login-input"
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[{ required: true, message: "Please enter your password" }]}
                  label={<span className="login-label">Password</span>}
                >
                  <Input.Password
                    prefix={<LockOutlined className="login-icon" />}
                    placeholder="Enter your password"
                    className="login-input"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>

                <div className="login-options">
                  {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item> */}
                  <Tooltip title="If you forget your password, please call your Provider's office or clinic to recover your login credentials.">
                    <Link className="login-forgot-link">Forgot Password?</Link>
                  </Tooltip>
                </div>

                <div className="captcha-container">
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                    onChange={handleCaptchaChange}
                  />
                </div>

                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="login-button"
                    loading={loading}
                    disabled={!captchaToken}
                    type={!captchaToken ? "default" : "primary"}
                  >
                    {loading ? "Signing in..." : "Sign In"}
                  </Button>
                </Form.Item>
              </Form>

              <div className="login-footer">
                <Text type="secondary" className="login-terms">
                  By signing in, you agree to our{" "}
                  <a
                    href="https://www.americanemr.com/terms--conditions.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms
                  </a>{" "}
                  &{" "}
                  <a
                    href="https://www.americanemr.com/privacy-policy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </Text>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
