import { Card, Typography, Row, Col, Tooltip } from "antd";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

const { Paragraph } = Typography;

const FEATURE_CARDS = [
  {
    id: 'vitals',
    path: '/vital-signs',
    title: 'Basic Vitals',
    description: 'View the latest updates on your vitals and health metrics.',
    bgColor: 'bg-blue-50',
    textColor: 'text-blue-700',
    tooltipText: 'Click here to view the latest updates on your vitals and health metrics.',
    hoverGradient: 'hover:bg-gradient-to-r from-blue-50 to-indigo-100'
  },
  {
    id: 'prescriptions',
    path: '/medications',
    title: 'Prescriptions',
    description: 'Access your most recent prescriptions and medication details.',
    bgColor: 'bg-green-50',
    textColor: 'text-green-700',
    tooltipText: 'Click here to access your most recent prescriptions and medication details.'
  },
  {
    id: 'ordered-labs',
    path: '/ordered-labs',
    title: 'Ordered Labs',
    description: 'View all your requested lab tests and upcoming appointments.',
    bgColor: 'bg-purple-50',
    textColor: 'text-purple-700',
    tooltipText: 'Click here to view all your requested lab orders'
  },
  {
    id: 'lab-results',
    path: '/lab-results',
    title: 'Lab Results',
    description: 'Check out your recent lab results and historical data.',
    bgColor: 'bg-amber-50',
    textColor: 'text-amber-700',
    tooltipText: 'Click here to view your recent lab results'
  }
];

const FeatureCard = ({ card, onClick }) => (
  <Col xs={24} md={12} className="dashboard-card">
    <Tooltip title={card.tooltipText}>
      <div 
        className={`${card.bgColor} p-4 rounded-lg shadow-sm h-full cursor-pointer ${card.hoverGradient || ''}`}
        onClick={onClick}
      >
        <h3 className={`text-lg font-medium ${card.textColor} mb-2`}>{card.title}</h3>
        <p className="text-gray-700">{card.description}</p>
      </div>
    </Tooltip>
  </Col>
);

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    try {
      const userDataString = sessionStorage.getItem('userData');
      if (userDataString && isMounted) {
        const userDataObject = JSON.parse(userDataString);
        setUserData(userDataObject);
      }
    } catch (err) {
      if (isMounted) {
        setError('Failed to load user data');
        console.error('Error parsing user data:', err);
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const handleNavigate = useCallback((path) => () => {
    navigate(path);
  }, [navigate]);

  const renderFeatureCards = useMemo(() => (
    <Row gutter={[16, 16]} className="mt-4 vitals-summary-section">
      {FEATURE_CARDS.map(card => (
        <FeatureCard
          key={card.id}
          card={card}
          onClick={handleNavigate(card.path)}
        />
      ))}
    </Row>
  ), [handleNavigate]);

  if (error) {
    return (
      <div className="text-center text-red-600 p-4">
        {error}
      </div>
    );
  }

  return (
    <div className="min-h-[calc(100vh-100px)] flex justify-center items-start rounded-xl">
      <div className="w-full p-4 md:p-6 lg:p-8">
        <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-center bg-blue-200 text-blue-800 p-3 md:p-4 mb-4 md:mb-6 lg:mb-8 rounded-xl md:rounded-2xl shadow-sm">
          Welcome to {userData?.clinic_name || "Our"} Patient Portal
        </h1>

        <Card className="p-3 md:p-5 lg:p-6 rounded-xl md:rounded-3xl border border-gray-200 shadow-md">
          <div className="text-center mb-4 md:mb-6">
            <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-2">
              Your Personalized Healthcare Hub
            </h2>
            <p className="text-sm md:text-base text-gray-600">
              Access all your medical reports, track your vitals, and stay informed with ease.
            </p>
          </div>

          <div className="space-y-3 md:space-y-4">
            <Paragraph className="text-base md:text-lg text-gray-700 leading-relaxed">
              Welcome to your personal patient portal. Here, you'll access all your medical reports and receive updates on basic healthcare vitals from your provider.
            </Paragraph>
            <Paragraph className="text-base md:text-lg text-gray-700 leading-relaxed">
              Take advantage of this secure online service provided by your healthcare provider, with the assurance that your personal information is encrypted and stored securely.
            </Paragraph>
            <Paragraph className="text-base md:text-lg font-semibold text-red-600 leading-relaxed">
              ⚠️ Remember! In case of a life-threatening emergency, always call your local emergency services (e.g. 911 in USA)
            </Paragraph>
            <Paragraph className="text-base md:text-lg text-gray-700 leading-relaxed">
              Below are some helpful tips to navigate the portal:
            </Paragraph>
          </div>

          {renderFeatureCards}
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
