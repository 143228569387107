import React, { useEffect, useState } from 'react';
import {
  MenuOutlined,
  CommentOutlined,
  HomeOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { Layout, Menu, Button, theme, Drawer, Typography, Divider } from 'antd';
import Logo from '../../Assets/logo.png'
import LogoCollapse from '../../Assets/star.png'
import { useNavigate, useLocation } from 'react-router-dom';
import PatientProfile from '../PatientProfile/PatientProfile';
import { FaFlaskVial } from "react-icons/fa6";
import { TbReportSearch } from "react-icons/tb";
import { FaHeartbeat } from "react-icons/fa";
import { FaStaffSnake } from "react-icons/fa6";
import { useIdle } from "@uidotdev/usehooks";
import { useUser } from '../../context/UserContext';
const { Header, Sider, Content } = Layout;
const { Title } = Typography;

const items = [
  {
    key: '/dashboard',
    icon: <HomeOutlined />,
    label: 'Home',
  },
  {
    key:'/vital-signs',
    icon: <FaHeartbeat/>,
    label:'Basic Vitals'
  },
  {
    key: '/medications',
    icon: <FaStaffSnake />,
    label: 'Prescriptions',
  },
  {
    key: '/ordered-labs',
    icon: <FaFlaskVial />,
    label: 'Ordered Labs',
  },
  {
    key: '/lab-results',
    icon: <TbReportSearch />,
    label: 'Lab Results',
  },
  {
    key: '/providers-comments',
    icon: <CommentOutlined/>,
    label: "Provider's Comments",
  },
]

const App = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { appLogoData, getAppLogo, logout } = useUser();
  const [collapsed, setCollapsed] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [userData, setUserData] = useState(null);
  const idle = useIdle(600000);

  const handleLogout = () => {
    logout();
  }

  useEffect(() => {
    if (idle) {
      handleLogout();
    } 
  }, [idle]);

  useEffect(() => {
    getAppLogo();
    
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userDataObject = JSON.parse(userDataString);
      setUserData(userDataObject);
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 768;
      setMobileView(isMobile);
      
      // Update collapsed state based on screen width
      if (window.innerWidth >= 992) {
        setCollapsed(false); // Always expanded for large screens
      } else if (window.innerWidth < 992 && window.innerWidth >= 768) {
        setCollapsed(true); // Collapsed for medium screens
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const {
    token: { colorBgContainer, colorPrimary, borderRadiusLG },
  } = theme.useToken();

  const sidebarContent = (
    <>
      <div
        className="flex justify-center brand py-4"
        style={{
          transition: "all 0.2s",
        }}
      >
        {collapsed && !mobileView ? (
          <img src={LogoCollapse} alt="Logo" className="w-[40px] my-2" />
        ) : (
          <img src={appLogoData?.icon || Logo} alt="Logo" className="w-[8rem] my-2" />
        )}
      </div>
      <Menu
        theme="dark"
        mode="inline"
        className='mt-2'
        defaultSelectedKeys={['1']}
        selectedKeys={[location.pathname]}
        onClick={({ key }) => {
          navigate(key);
          if (mobileView) {
            setDrawerVisible(false);
          }
        }}
        items={items}
      />
      
      {!collapsed && userData?.office_logo && (
        <div className="mt-auto mb-4 px-4 office-logo-container">
          <Divider className="my-2 bg-gray-600" />
          {/* <div className="flex flex-col items-center">
            <img
              src={userData?.office_logo}
              alt="Office Logo"
              className="max-w-[80%] max-h-16 object-contain"
            />
            {userData?.clinic_name && (
              <p className="text-white text-sm mt-2 text-center">{userData.clinic_name}</p>
            )}
          </div> */}
        </div>
      )}
      
      <div className="p-4 w-full flex justify-center items-center mb-2">
        <Button
          type="primary"
          danger
          icon={<LogoutOutlined />}
          onClick={handleLogout}
          className="flex items-center justify-center shadow-md hover:scale-105 transition-all"
          style={{ borderRadius: borderRadiusLG }}
        >
          {(!collapsed || mobileView) && "Logout"}
        </Button>
      </div>
    </>
  );

  return (
    <Layout className='min-h-screen'>
      {mobileView ? (
        <Drawer
          placement="left"
          onClose={toggleDrawer}
          open={drawerVisible}
          width={250}
          bodyStyle={{ padding: 0, background: '#001529' }}
          headerStyle={{ display: 'none' }}
          className="drawer-menu-container"
        >
          <div className="flex flex-col h-full">
            {sidebarContent}
          </div>
        </Drawer>
      ) : (
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={256}
          collapsedWidth={80}
          className="fixed h-full z-30 transition-all duration-300 shadow-lg"
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <div className="flex flex-col h-full">
            {sidebarContent}
          </div>
        </Sider>
      )}
      <Layout>
        <Header
          style={{
            padding: '0 16px',
            background: colorBgContainer,
            width: "100%",
            position: "fixed",
            zIndex: 29,
            transition: "all 0.2s",
            marginLeft: mobileView ? 0 : (collapsed ? "80px" : "256px"),
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            height: '64px',
            right: 0,
          }}
          className="flex items-center justify-between"
        >
          <div className="flex items-center flex-shrink-0">
            <Button
              type="text"
              icon={<MenuOutlined />}
              onClick={mobileView ? toggleDrawer : () => setCollapsed(!collapsed)}
              className="mr-3 flex items-center justify-center flex-shrink-0"
              style={{ fontSize: '16px' }}
            />
          </div>
          
          {/* Office Logo - Always visible in header */}
          <div className="flex-grow flex justify-center items-center">
            {userData?.office_logo && (
              <div className="office-logo-header">
                <div className="flex items-center">
                  <img 
                    src={userData?.office_logo} 
                    alt="Office Logo" 
                  />
                  {userData?.clinic_name && (
                    <h1 className="hidden md:block">{userData.clinic_name}</h1>
                  )}
                </div>
              </div>
            )}
          </div>
          
          <div className="flex justify-end pr-2 md:pr-4 flex-shrink-0">
            <PatientProfile showClinicInfo={false} />
          </div>
        </Header>
        <Content
          style={{
            padding: mobileView ? "12px" : "20px",
            marginLeft: mobileView ? 0 : (collapsed ? "80px" : "256px"),
            background: '#f5f7fa',
            marginTop: "64px",
            minHeight: "calc(100vh - 64px)",
            transition: "all 0.2s",
          }}
          className="overflow-auto"
        >
          <div 
            style={{ 
              background: colorBgContainer, 
              padding: mobileView ? '16px' : '24px',
              borderRadius: borderRadiusLG,
              boxShadow: '0 1px 3px rgba(0,0,0,0.05)'
            }}
            className="h-full"
          >
            {props.children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;